import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { IsUserLogged, IsUserNotLogged } from '@get/core/guards';
import { AppResolver } from './app.resolver';
import { BasePageComponent } from './base-page/base-page.component';

const routerOptions: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload'
};

const routes: Routes = [
  {
    path: '',
    component: BasePageComponent,
    children: [
      {
        path: 'app',
        canActivate: [IsUserLogged],
        resolve: { AppResolver },
        loadChildren: () => import('@get/layout').then(m => m.LayoutRoutingModule),
        data: {
          breadcrumb: 'Accueil'
        }
      },
      {
        path: 'auth',
        canActivate: [IsUserNotLogged],
        loadChildren: () => import('@get/auth/shell').then(m => m.GetAuthShellModule)
      },
      { path: '**', redirectTo: 'app' }
    ]
  }
];

@NgModule({
  imports: [BasePageComponent, RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
