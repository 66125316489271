import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { UpdateComponent } from '@get/shared/update';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

// TODO: Transformer en standalone
@UntilDestroy()
@Component({
  selector: 'get-base-page',
  templateUrl: './base-page.component.html',
  standalone: true,
  imports: [CommonModule, UpdateComponent, RouterModule]
})
export class BasePageComponent implements OnInit {
  public updateApp = false;

  constructor(private swUpdate: SwUpdate) {}

  public ngOnInit(): void {
    this.checkVersion();
  }

  private checkVersion(): void {
    if (this.swUpdate?.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          untilDestroyed(this),
          filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
        )
        .subscribe(() => {
          this.updateApp = true;
        });
      setTimeout(() => {
        setInterval(async () => {
          const available = await this?.swUpdate?.checkForUpdate();
          if (available) {
            this.updateApp = true;
          }
        }, 1000 * 60 * 5);
      }, 5000);
    }
  }
}
